import { Grid } from "grommet";
import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import Constants from "../constants";
import Layout from "../components/layout";
import RecipeWrapper from "../components/recipe-wrapper";
import SEO from "../components/seo";

const StyledHeadline = styled.h1`
  font-size: 35px;
  @media (max-width: ${Constants.mobileWidth}) {
    font-size: 18px;
  }
`;

const StyledGrid = styled(Grid)`
  @media (max-width: ${Constants.mobileWidth}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const RecipeText = styled.p`
  margin-top: 10px;
`;

const RecipeSection = styled.div``;

const title = `Plant-Based & Vegetarian Recipes`;

const Recipes = (
  { data: { avatar, backdrop, logo, ogImage, recipes, site } },
  location
) => (
  <Layout avatar={avatar} backdrop={backdrop} location={location} site={site}>
    <SEO
      avatar={avatar}
      logo={logo}
      title={`${title} - She & Jim`}
      description={`Plant based and vegetarian recipes from She & Jim`}
      postData={{
        author: "James Simone",
        image: ogImage.childImageSharp.original.src,
        imageAlt: `cooking with She & Jim`,
        keywords: recipes.edges
          .map(({ node }) => node.data.Keywords.split(","))
          .reduce(
            (array1, array2) =>
              array1.concat(array2.filter(item => array1.indexOf(item) < 0)),
            []
          )
      }}
      site={site}
    />
    <RecipeSection itemScope={true} itemType="https://schema.org/WebPage">
      <StyledHeadline itemProp="headline">{title}</StyledHeadline>
      <RecipeText id="recipeText" itemProp="description">
        We're always working on new vegetarian and vegan recipes. We are
        primarily plant-based eaters and are focused on providing whole-body
        nutrition from whole food ingredients. Our home is our test kitchen and
        we love having the ability to share our successes. Whether you're
        cooking plant-based for the first time or a seasoned vegan vet, we
        promise there'll be recipes on here that you can't find anywhere else!
      </RecipeText>
      <StyledGrid
        gap={"medium"}
        columns={[`1fr`, `1fr`, `1fr`]}
        margin="1rem 0 0 0"
      >
        {recipes &&
          recipes.edges &&
          recipes.edges.length > 0 &&
          recipes.edges.map(recipe => (
            <RecipeWrapper
              key={recipe.node.id}
              recipe={recipe.node}
              showText={true}
            />
          ))}
      </StyledGrid>
    </RecipeSection>
  </Layout>
);

export default Recipes;

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      ...Avatar
    }

    backdrop: file(absolutePath: { regex: "/mountains.png/" }) {
      ...Backdrop
    }

    logo: file(absolutePath: { regex: "/she-and-jim-logo.jpg/" }) {
      ...Logo
    }

    ogImage: file(absolutePath: { regex: "/cooking-with-she-and-jim.jpg/" }) {
      ...Logo
    }

    recipes: allAirtable(
      filter: { table: { eq: "Recipes" }, data: { Published: { eq: true } } }
      sort: { fields: data___PublishDate, order: DESC }
    ) {
      edges {
        node {
          ...RecipeWithPhotos
        }
      }
    }

    site {
      siteMetadata {
        authors
        social {
          instagram
        }
        siteUrl
        subTitle
        title
      }
    }
  }
`;
